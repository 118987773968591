import React from "react";
import BaseLayout from "../components/base-layout/base-layout";
import terraMaegaSmallCapsule from "../images/small-capsule-new.png";
import steamIcon from "../images/steam-icon.png";
import youtubeIcon from "../images/youtube-icon.png";
import kickstarterIcon from "../images/kickstarter-icon.png";
import twitterIcon from "../images/twitter-icon.png";
import instagramIcon from "../images/instagram-icon.png";
import { Link, HeadFC } from "gatsby";

export default function NotFoundPage() {
  const globalStyle = {
    color: "#f6f5f7",
  };
  const innerStyle = {
    minHeight: "calc(100vh - 71px)",
    padding: "15vh 5vw",
  }

  class SocialInfo {
    public id: string;
    public url: string;
    public label: string;
    public icon: any;

    public constructor(id: string, url: string, label: string, icon: any) {
      this.id = id;
      this.url = url;
      this.label = label;
      this.icon = icon;
    }
  }

  const socialInfoList : SocialInfo[] = [
    new SocialInfo(
      "steam",
      "https://store.steampowered.com/app/1858410/Terra_Maega/",
      "Visit Steam Page",
      steamIcon
    ),
    new SocialInfo(
      "kickstarter",
      "https://www.kickstarter.com/projects/daemontale/terra-maega",
      "Visit Kickstarter",
      kickstarterIcon
    ),
    new SocialInfo(
      "twitter",
      "https://twitter.com/daemontale",
      "Visit Twitter Page",
      twitterIcon
    ),
    new SocialInfo(
      "youtube",
      "https://www.youtube.com/channel/UCjmTJhu1slWpeaQreC6x7BQ",
      "Visit Youtube Channel",
      youtubeIcon
    ),
    new SocialInfo(
      "instagram",
      "https://www.instagram.com/daemon_tale/",
      "Visit Instagram Account",
      instagramIcon
    )
  ];

  return (
    <div style={globalStyle}>
      <BaseLayout>
        <div style={innerStyle}>
          <h1 className="py-4">
            🔨 &nbsp; Unavailable or Under Construction &nbsp; 🔨
          </h1>
          <p>
            Thanks for visiting!
            <br />
            <br />
            This page is currently not available, but feel free to check out:
            <br />
            <br />
            <img
              src = {terraMaegaSmallCapsule}
              className = "d-block m-auto terra-maega-small-capsule"
              alt = "terra maega banner"
              width = "231"
              height = "87"
            />
            <Link to="/terra-maega">Terra Maega</Link>.
            <br />
            <br />
            Which is currently in development 😃
            <br />
            <br />
            {socialInfoList.map((info) => {
              return (
                <a
                  className = "btn d-inline-block mx-2 p-1 social-block-button"
                  href = {info.url}
                  aria-label = {info.label}
                  role = "button"
                  key = {info.id}
                  target = "_blank"
                  rel = "noopener noreferrer"
                >
                  <img
                    src = {info.icon}
                    className = "social-block-button-icon"
                    alt = "button icon"
                    width = "48"
                    height = "48"
                  />
                </a>
              )
            })}
          </p>
        </div>
      </BaseLayout>
    </div>
  )
}

export const Head: HeadFC = () => <title>Not Found</title>
